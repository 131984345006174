import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getTopper } from '../Service/Api';
import { Link } from 'react-router-dom';
const Toppers = () => {
  const [classXData, setClassXData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getTopper();
      const currentYear = new Date().getFullYear();
      const yearString = `${currentYear - 1}-${currentYear}`;
      
      const classX = topperData.filter((item) => item.class === 'X' && item.sessionYear === yearString);
      setClassXData(classX); 
      
      console.log("Filtered class X data for year", yearString, classX);
    };
    fetchData();
  }, []);

  const emptyArray = [
    { name: "Student Name", division: "%",Subject:"Subject" },
    { name: "Student Name", division: "%",Subject:"Subject" },
    { name: "Student Name", division: "%",Subject:"Subject" },
    { name: "Student Name", division: "%",Subject:"Subject" }
  ];
  var settings = {
    autoplay: true,
    infinite: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
   arrows:false,
    responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 1, 
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2, 
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1, 
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
          }
        }
      ]
  };
  return (
     <>
     <div className="row">
     {classXData.length > 0 ? (
        <Slider {...settings}> 
          {classXData.map((item1, index) => (
               <div key={index}>
                 <div className="acheiver-blk">
                      <p className="board">10 <sup>th</sup>  <Link to="/TopperX">  <i className="bi bi-arrow-right-circle"></i>  </Link></p>
                     <div className="acheiver-image"> 
                         <img src={`https://webapi.entab.info/api/image?url=${item1?.attachments}`} alt="St. Francis School, Ranchi"/>
                     </div>
                     <div className="content">
                        <img src="/Images/acheivements.gif" className="balon-gif" alt="St. Francis School, Ranchi" /> 
                         <h3>{item1?.name}  </h3>
                         <p>{item1?.stream}</p>
                         <div className="percentage">
                         {item1?.division}
                     </div>
                    
                     </div> 
                     
                 </div>
            </div>
           ))}
           </Slider>
           ) : (
             <Slider {...settings}> 
               {emptyArray.map((data, index) => (
                 <div className="item" key={index}>
                       <div className="acheiver-blk">
                      <p className="board">10 <sup>th</sup> <Link to="/TopperX">  <i className="bi bi-arrow-right-circle"></i>  </Link></p>
                     <div className="acheiver-image"> 
                         <img src="/Images/defaultImage.jpg" alt="St. Francis School, Ranchi"/>
                     </div>
                     <div className="content">
                        <img src="/Images/acheivements.gif" className="balon-gif" alt="St. Francis School, Ranchi" /> 
                         <h3>{data.name}  </h3>
                         
                         <p>{data.Subject}</p>
                         <div className="percentage">
                         {data.division}
                     </div>
                    
                     </div> 
                     
                 </div> 
                    </div>
                    ))}
                    </Slider>
                  )}
                </div>
     </>
  )
}

export default Toppers
