import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom' 
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
 
     <>
       
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li> 
            <li> School </li>
            <li> Principal's Message </li>
        </ul>
    </div>
    <div className="innersec">
      <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Principal's Message </h1>
                </div>
            </div>
            {data.length > 0 ? data.map((item) => (
                <div className="clearfix">
                 <img src={`https://webapi.entab.info/api/image?url=${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt=""/>
            
                   <p>{item.message}</p> 
                  <p className="name"> {item.name}<span>  {item.category} </span></p>
          
             
            </div> 
            )) : emptyData.map((item) => (
                <div className="clearfix"> 
                <img src="/Images/principal.jpg" className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt=""/>
                <p> All our lives we have been taught that education fetches tremendous change in society. It does. Education is the most significant instrument in determining the economic and societal transformation of people. The best thing about the education system in India is that it prepares the foundation of a child’s schooling very skilfully by giving equal importance to all subjects. Educational institutions contribute to the shaping up of the efficiency of manpower within a society. As of late, the invasion of technology in every sphere of life has changed the outlook of education in India. The different technologies introduced in the learning process have made it easy to interact with teachers and students across the globe. However, the deterioration in the area of integral growth of human development has raised many questions about the present educational system. To create value-based learning experiences for students, experts have directed teachers and educators to adopt different pedagogies, methods or programmes. <br/>
                St. Francis school has been imparting education for more than four decades about social, cultural, spiritual and ethical values. Here I would like to insist that we must collectively delve deeper into these facets of our education. Today we need a value-based revolution in the minds of youngto mould a society that would be whole and humane. As technology occupies daily functions, life is becoming intricate and complex. Loss of values is reflected now in every sphere of human life. Standards of the moral and social life of our people are gradually decreasing. The ideals of family, society, politics, professional ethics, etc. are declining and bringing tremendous tension. Hence, it is the institutional and individual responsibility of everyone to inculcate belief in higher ideals. <br/>
                As I conclude and sum up my short message, I would like to quote a famous Indian writer Shakuntala Devi (popularly known as ‘human computer’), “Education is not just about going to school and getting a degree. It's about widening your knowledge and absorbing the truth about life.”  <br/> Greetings and Best Wishes  </p>
                <p className="name"> Fr. Shaji Alappurath <span>  Principal </span> </p> 
                </div> 
                ))}
        </div>
    </div>
       
     </>
  )
}

export default PrincipalMessage
