import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom' 
import { getOnlineRegistration } from "../Service/Api";
const AlumniRegistration = () => {
    const [data, setData] = useState([]); 
    useEffect(() => {
     const fetchData = async () => {
       try {
         const data = await getOnlineRegistration();
         setData(data);
       } catch (error) {
         console.error("Error fetching online registration data:", error);
       }  
     };
     fetchData();
   }, []);
   const emptyArray =[]
  return (
    <>
    {data.length > 0 ? (
      data.map((item, index) => (
         <div className="alumni-register"  key={index}>
          <p><Link to="/" target="_blank"><div className="AlumniRegistrationbox"><img src="/Images/AlumniRegistration.png" alt="St. Francis School  Harmu, Ranchi "/></div></Link></p>
          <p><Link to="/" target="_blank">  <span>{item.title}</span> </Link></p> 
    </div>
    ))
    ) : (
      emptyArray.map((item, index) => <div key={index}></div>)
    )}
    </>
  )
}

export default AlumniRegistration
